import React, { useEffect } from 'react';
import './AboutUsSideMenu.scss';

const sideMenuUpdate = () => {
  const sideMenu = document.querySelector('.about-us-side-menu');
  if (!sideMenu) {
    return;
  }
  const sections = document.querySelectorAll('.about-us-section');
  const sectionsY: number[] = [];
  sections.forEach((section) => sectionsY.push(section.getBoundingClientRect().y));
  const sectionsAbsY: number[] = sectionsY.map((y) => Math.abs(y));
  const sectionIdx = sectionsAbsY.findIndex((y) => y === Math.min(...sectionsAbsY));
  Array.from(sideMenu.children).forEach((c, i: number) => {
    if (i === sectionIdx) {
      c.classList.add('mod-active');
    } else {
      c.classList.remove('mod-active');
    }
  });
};

const AboutUsSideMenu = (): JSX.Element => {
  useEffect(() => {
    // Initialize side menu
    sideMenuUpdate();

    // Listen for scroll / resize
    window.addEventListener('scroll', sideMenuUpdate);
    window.addEventListener('touchmove', sideMenuUpdate);
    window.addEventListener('resize', sideMenuUpdate);
    return () => {
      window.removeEventListener('scroll', sideMenuUpdate);
      window.removeEventListener('touchmove', sideMenuUpdate);
      window.removeEventListener('resize', sideMenuUpdate);
    };
  }, []);

  return (
    <div className="about-us-side-menu" data-sal="fade" data-sal-duration="300">
      <button
        type="button"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        About
      </button>
      <button
        type="button"
        onClick={() => {
          // padding-top 12px for each section
          window.scrollTo(0, (document.getElementById('about-us-service')?.offsetTop || 0) + 24);
        }}
      >
        Service
      </button>
      <button
        type="button"
        onClick={() => {
          // padding-top 12px for each section
          window.scrollTo(0, (document.getElementById('about-us-culture')?.offsetTop || 0) + 36);
        }}
      >
        Culture
      </button>
    </div>
  );
};

export default AboutUsSideMenu;
