import React, { useRef, useState } from 'react';
import './InteractiveImage.scss';

interface Props {
  baseImg: string;
  overlayImg: string;
}

const InteractiveImage = ({ baseImg, overlayImg, ...others }: Props): JSX.Element => {
  const [isDown, _setIsDown] = useState(false);
  const isDownRef = useRef(isDown);
  const setIsDown = (down: boolean) => {
    isDownRef.current = down;
    _setIsDown(down);
  };
  const [mouseSliderPos, setMouseSliderPos] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLButtonElement>(null);
  const overlayRef = useRef<HTMLImageElement>(null);

  const onMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDown(true);
    let pageX = 0;
    if (e.type === 'touchstart') {
      pageX = ((e as unknown) as TouchEvent).touches[0]?.pageX || 0;
    } else {
      pageX = ((e as unknown) as MouseEvent).pageX || 0;
    }
    setMouseSliderPos(pageX - (sliderRef.current?.getBoundingClientRect().x || 0));
  };

  const onMouseLeaveOrUp = () => {
    setIsDown(false);
    setMouseSliderPos(undefined);
  };

  const onMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDown || !sliderRef.current || !containerRef.current) {
      return;
    }
    if (e instanceof MouseEvent) {
      e.preventDefault();
    }

    const containerWidth = containerRef.current.clientWidth;
    const sliderWidth = sliderRef.current.clientWidth;
    let pageX = 0;
    if (e.type === 'touchmove') {
      pageX = ((e as unknown) as TouchEvent).touches[0]?.pageX || 0;
    } else {
      pageX = ((e as unknown) as MouseEvent).pageX || 0;
    }
    const x = pageX - containerRef.current.getBoundingClientRect().x;
    let sliderNewPos = Math.max(x - (mouseSliderPos || 0), -41);
    if (sliderNewPos + sliderWidth / 2 > containerWidth) {
      sliderNewPos = containerWidth - sliderWidth / 2;
    }
    sliderRef.current.style.left = `${sliderNewPos}px`;

    if (!overlayRef.current) {
      return;
    }
    const overlayMax = sliderNewPos + sliderWidth / 2 - 21;
    overlayRef.current.style.clipPath = `polygon(0 0, ${overlayMax}px 0, ${overlayMax}px 100%, 0 100%)`;
  };

  return (
    <div className="interactive-image" {...others}>
      <div className="interactive-image-filter" ref={containerRef}>
        <img className="interactive-image-overlay" ref={overlayRef} src={overlayImg} alt="" />
        <button
          className="interactive-image-slider"
          type="button"
          ref={sliderRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeaveOrUp}
          onMouseUp={onMouseLeaveOrUp}
          onMouseMove={onMouseMove}
          onTouchStart={onMouseDown}
          onTouchMove={onMouseMove}
          onTouchEnd={onMouseLeaveOrUp}
        >
          <div className="interactive-image-slider-border" />
          <div className="interactive-image-slider-drag" />
        </button>
      </div>
      <img src={baseImg} alt="" />
      <p>Slide right to see what our colleagues are thinking!</p>
    </div>
  );
};

export default InteractiveImage;
