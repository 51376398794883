import React, { useEffect } from 'react';
import Layout from '../../layouts/layout';
import Accordion from '../../components/Accordion/Accordion';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import InteractiveImage from '../../components/InteractiveImage/InteractiveImage';
import WavingHand from '../../components/WavingHand/WavingHand';
import AboutUsSideMenu from '../../components/AboutUsSideMenu/AboutUsSideMenu';
import ABOUT_COLLEAGUES from '../../images/about-colleagues.jpg';
import ABOUT_COLLEAGUES2 from '../../images/about-colleagues2.jpg';
import ICON_CODE from '../../images/icon-code.png';
import ICON_DESIGN from '../../images/icon-design.png';
import ICON_WORK from '../../images/icon-work.png';
import ICON_HOST from '../../images/icon-host.png';
import ICON_TECHNICAL from '../../images/icon-technical.png';
import './about.scss';

const About = (): JSX.Element => {
  useEffect(() => {
    if (window.location.hash.length > 0) {
      setTimeout(() => {
        const target = document.querySelector(window.location.hash);
        if (target) {
          target.scrollIntoView();
        }
      }, 600);
    }
  }, []);

  return (
    <Layout title="About" path="about">
      <div className="about-us">
        <Header />
        <div className="about-us-container">
          <div className="about-us-content">
            <section className="about-us-section" id="about-us-about">
              <p className="about-us-mobile-section-head" data-sal="slide-up" data-sal-duration="600">
                About
              </p>
              <h1 data-sal="slide-up" data-sal-duration="600">
                DESIGN,
                <br />
                DEVELOP,
                <br />
                DELIVER
              </h1>
              <p data-sal="slide-up" data-sal-duration="600">
                ......and drink. We do celebrate success with a pint (...or two). But before that, we have a mission to
                accomplish. We are formed and committed to raise the bar of the tech industry - app designers and
                developers are professionals no different from lawyers and accountants. It requires specialized
                knowledge and skills. Our job is to provide an environment and culture for our people to grow step by
                step, to visualize the career path for them to become a tech solution partner who can take charge.
              </p>
              <InteractiveImage
                baseImg={ABOUT_COLLEAGUES}
                overlayImg={ABOUT_COLLEAGUES2}
                data-sal="slide-left"
                data-sal-duration="600"
              />
            </section>
            <section className="about-us-section" id="about-us-service">
              <p className="about-us-mobile-section-head" data-sal="slide-up" data-sal-duration="600">
                Services
              </p>
              <div id="technical-consulting" className="about-us-service-item">
                <img src={ICON_TECHNICAL} alt="" data-sal="slide-up" data-sal-duration="600" />
                <div className="about-us-service-text" data-sal="slide-up" data-sal-duration="600">
                  <h2>Technical Consulting</h2>
                  <p>
                    From security enhancement to cloud migration, from data analytics to performance tuning, App Bar's
                    consultants help you understand the problems you are facing. On some situation, we may advise you to
                    use microservices to allow flexibility of choice of technology; else, we may also suggest to use
                    monolith app to have a unified system.
                  </p>
                </div>
              </div>
              <div id="ui-ux" className="about-us-service-item">
                <img src={ICON_DESIGN} alt="" data-sal="slide-up" data-sal-duration="600" />
                <div className="about-us-service-text" data-sal="slide-up" data-sal-duration="600">
                  <h2>UI/UX Design</h2>
                  <p>
                    When it comes to design, it is never only about creativity - it consists of a process of evaluation,
                    analysis, research, user interview, and finally visualize the design.
                  </p>
                </div>
              </div>
              <div id="development-and-engineering" className="about-us-service-item">
                <img src={ICON_CODE} alt="" data-sal="slide-up" data-sal-duration="600" />
                <div className="about-us-service-text" data-sal="slide-up" data-sal-duration="600">
                  <h2>Development & Engineering</h2>
                  <p>
                    We are the disciple of modern software engineering principles: CRUD, REST, and DRY (Don't repeat
                    yourself). App Bar takes different approaches to the development process, based on different project
                    needs. Whether it is waterfall, agile, or Kanban, we leverage continuous integrations / continuous
                    delivery (CICD) to enhance the efficiency, and release your app faster.
                  </p>
                </div>
              </div>
              <div id="our-product" className="about-us-service-item">
                <img src={ICON_WORK} alt="" data-sal="slide-up" data-sal-duration="600" />
                <div className="about-us-service-text" data-sal="slide-up" data-sal-duration="600">
                  <h2>Our Product</h2>
                  <p>
                    <em>Employee engagement platform:</em>
                    <br />
                    Mission to put employees and leaders together and enhance the engagement rate through gamification
                    <br />
                    <em>Digital reward:</em>
                    <br />
                    Mission to group and offer various digital rewards to corporate companies
                  </p>
                </div>
              </div>
              <div className="about-us-service-item" data-sal="slide-up" data-sal-duration="600">
                <img src={ICON_HOST} alt="" />
                <div className="about-us-service-text">
                  <h2>Cloud Management</h2>
                  <p>
                    Just like property management, we can be your management office - whether your "office" is built on
                    AWS Cloud or MS Azure. When your app scales and needs a bigger or more "consistent offices" as the
                    business grows, App Bar's consultants help you provision the resources by using infrastructure as
                    code, which significantly reduces the processing time of infrastructure deployment.
                  </p>
                </div>
              </div>
            </section>
            <section className="about-us-section" id="about-us-culture">
              <p className="about-us-mobile-section-head" data-sal="slide-up" data-sal-duration="600">
                Culture
              </p>
              <h1 data-sal="slide-up" data-sal-duration="600">
                NO MORE
                <br />
                MONDAY
                <br />
                BLUES
              </h1>
              <p data-sal="slide-up" data-sal-duration="600">
                With a happy work environment, we can deliver the most extraordinary products.
              </p>
              <p data-sal="slide-up" data-sal-duration="600">
                World-class apps take more than intelligent code or creative design. It requires a truly supportive work
                environment of ideators and innovators. Our collaborations are built on a bedrock of transparency,
                authenticity, and fun.
              </p>
              <div className="about-us-accordions">
                <Accordion title="Transparency">
                  <p>
                    Your project’s status, budget spend, and progress are always visible and trackable by you. We
                    believe complete transparency forms healthy communication, and healthy communication builds great
                    products.
                  </p>
                </Accordion>
                <Accordion title="Authenticity">
                  <p>
                    Authenticity is about honesty to ourselves. This is why we don't say things that we don't
                    understand. We don't do work that we are not professional at.
                  </p>
                </Accordion>
                <Accordion title="Fun">
                  <p>Some banter never hurts. Some beer's never enough.</p>
                </Accordion>
              </div>
            </section>
          </div>
          <AboutUsSideMenu />
        </div>
        <WavingHand />
        <Footer />
      </div>
    </Layout>
  );
};

export default About;
